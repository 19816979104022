import { makeStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CommentIcon from "@material-ui/icons/Comment";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationImportantIcon from "@material-ui/icons/NotificationImportant";
import cx from "classnames";
import ProfileModal from "Components/ProfileModal/ProfileModal";
import OrganisationSelector from "Components/OrganizationSelector/OrganizationSelector";
import SideMenuLiftDetail from "Components/SideMenuLiftDetail/SideMenuLiftDetail";
import { isFunction, isObject } from "lodash";
import {
  EGlobalStateCases,
  GlobalStateContext,
  IGlobalState,
} from "Providers/GlobalStateProvider";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import LiftIcon from "../../Assets/LiftIcon";
import classes from "./SideMenu.module.scss";

const drawerWidth = 220;

// We still use some material styles here
//
const materialStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
}));

export interface ISideMenuProps {
  onChangeOrganisation?: (
    organisation: LiftStatus.Schemas.IOrganisation
  ) => void;
}

const SideMenu = (props: ISideMenuProps) => {
  const materialClass = materialStyles();
  const [drawerState, setDrawerStateOpen] = useState(true);
  const [, setOrganisation] = useState<LiftStatus.Schemas.IOrganisation>();
  const [selectedRoute, setSelectedRoute] = useState("");
  const [globalState, globalStateDispatch] =
    useContext<[IGlobalState, React.Dispatch<any>]>(GlobalStateContext);
  const [openProfileModal, setOpenProfileModal] = React.useState(false);

  const handleDrawerOpen = () => {
    setDrawerStateOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerStateOpen(false);
  };

  const location = useLocation();

  /**
   * Effect for route changes
   */
  useEffect(() => {
    const route = location?.pathname?.replace("/", "");
    setSelectedRoute(route);
  }, [location, selectedRoute]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={cx(
          materialClass.appBar,
          drawerState && materialClass.appBarShift
        )}
      ></AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: cx(
            materialClass.drawerPaper,
            !drawerState && materialClass.drawerPaperClose,
            !drawerState && classes.toolbarClosed
          ),
        }}
        open={drawerState}
      >
        <div className={classes.header}>
          {drawerState ? (
            <React.Fragment>
              <h3 className={classes.headerTitle}>
                <FormattedMessage id="liftstatus" />
              </h3>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon className={classes.white} />
              </IconButton>
            </React.Fragment>
          ) : (
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDrawerOpen}
              className={cx(
                classes.menuButton,
                drawerState && classes.menuButtonHidden
              )}
            >
              <MenuIcon className={classes.white} />
            </IconButton>
          )}
        </div>

        <List className={classes.menuItems}>
          <div className={classes.ogSelect}>
            <OrganisationSelector
              onChange={(changedOrganisation) => {
                if (isObject(changedOrganisation)) {
                  setOrganisation(changedOrganisation);
                  if (isFunction(props.onChangeOrganisation)) {
                    props.onChangeOrganisation(changedOrganisation);
                  }
                }
              }}
            ></OrganisationSelector>
          </div>

          <Link to="/dashboard" className={classes.link}>
            <ListItem
              button
              className={
                selectedRoute === "dashboard" ? classes.selectedRoute : null
              }
            >
              <ListItemIcon>
                <HomeIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                disableTypography={true}
                className={classes.itemText}
                primary={<FormattedMessage id="dashboard" />}
              />
            </ListItem>
          </Link>
          <Link to="/alarm-calls" className={classes.link}>
            <ListItem
              button
              className={
                selectedRoute === "calls" ? classes.selectedRoute : null
              }
            >
              <ListItemIcon>
                <NotificationImportantIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                disableTypography={true}
                className={classes.itemText}
                primary={<FormattedMessage id="calls" />}
              />
            </ListItem>
          </Link>

          { ( !globalState.userClientLight && !globalState.userMechanic ) ? (
            <Link to="/current-calls" className={classes.link}>
              <ListItem
                button
                className={
                  selectedRoute === "current-calls" ? classes.selectedRoute : null
                }
              >
                <ListItemIcon>
                  <NotificationImportantIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  className={classes.itemText}
                  primary={<FormattedMessage id="current.calls" />}
                />
              </ListItem>
            </Link>
          ) : (
            <></>
          )}

          { ( !globalState.userClientLight && !globalState.userMechanic ) ? (
            <Link to="/events" className={classes.link}>
              <ListItem
                button
                className={
                  selectedRoute === "events" ? classes.selectedRoute : null
                }
              >
                <ListItemIcon>
                  <CommentIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  className={classes.itemText}
                  primary={<FormattedMessage id="events" />}
                />
              </ListItem>
            </Link>
          ) : (
            <></>
          )}

          <Link to="/lifts" className={classes.link}>
            <ListItem
              button
              className={
                selectedRoute.includes("lifts") ? classes.selectedRoute : null
              }
            >
              <ListItemIcon>
                <LiftIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                disableTypography={true}
                className={classes.itemText}
                primary={<FormattedMessage id="lifts" />}
              />
            </ListItem>
          </Link>
          <ListItem
            onClick={() => setOpenProfileModal(true)}
            button
            className={classes.account}
          >
            <ListItemIcon>
              <AccountCircleIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              disableTypography={true}
              className={classes.accountName}
              primary={globalState.username}
            />
          </ListItem>
        </List>
        <SideMenuLiftDetail />
      </Drawer>
      <ProfileModal
        open={openProfileModal}
        setOpen={setOpenProfileModal}
        onConfirm={() => {
          globalStateDispatch({
            type: EGlobalStateCases.logOut,
          });
        }}
      ></ProfileModal>
    </div>
  );
};

export default SideMenu;
